import React from "react";
import PropTypes from "prop-types";
import { Plus } from "../../../../svgs";

const UpdateLoanSubmissionButton = ({ title = "", onClick = () => {} }) => {
  return (
    <div className="update-loan-submission__button-container">
      <div className="update-loan-submission__button-label">{title}</div>
      <div
        className="update-loan-submission__button-icon"
        role="button"
        onClick={onClick}
        tabIndex="0"
      >
        <Plus />
      </div>
    </div>
  );
};

UpdateLoanSubmissionButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
};

export default UpdateLoanSubmissionButton;
